import { createSignal, onMount } from 'solid-js';
import type { StripeElements } from '@stripe/stripe-js';

type Props = {
	elements: StripeElements;
};

export function ExpressCheckoutElement(props: Props) {
	const [ref, setRef] = createSignal<HTMLDivElement>();

	onMount(() => {
		const el = props.elements.create('expressCheckout', {
			paymentMethods: {
				googlePay: 'always',
				applePay: 'always',
				paypal: 'auto',
				// amazonPay: 'always',
				link: 'never',
			},
			wallets: {
				applePay: 'always',
				googlePay: 'always',
			},
		});

		el.mount(ref()!);
	});

	return <div ref={setRef} />;
}
