import { createSignal, onMount } from 'solid-js';
import { useUser } from '../../../../../providers/user';
import type { StripeElements } from '@stripe/stripe-js';

type Props = {
	elements: StripeElements;
};

export function AddresscElement(props: Props) {
	const [ref, setRef] = createSignal<HTMLDivElement>();
	const user = useUser();

	onMount(() => {
		const el = props.elements.create('address', {
			mode: 'billing',
			defaultValues: {
				name: `${user()?.me.firstName} ${user()?.me.lastName}`,
			},
			// allowedCountries: ['us', 'ca'],
		});

		el.mount(ref()!);
	});

	return <div ref={setRef} />;
}
