import { Show, Suspense } from 'solid-js';
import { Separator } from '@troon/ui';
import { useStripe } from '../../../../providers/stripe';
import { CardNumberElement } from './elements/card-number';
import { CardExpiryElement } from './elements/card-expiry';
import { CardCvcElement } from './elements/card-cvc';
import { AddresscElement } from './elements/address';
import { ExpressCheckoutElement } from './elements/express';

export function Elements() {
	const { elements } = useStripe();

	return (
		<Suspense>
			<Show when={elements()}>
				{(elements) => (
					<div class="flex flex-col gap-4">
						<ExpressCheckoutElement elements={elements()} />
						<Separator>or</Separator>
						<h3 class="text-xl font-semibold">Pay with card</h3>
						<div class="grid grid-cols-2">
							<div class="col-span-2">
								<CardNumberElement elements={elements()} />
							</div>
							<div class="col-span-1">
								<CardExpiryElement elements={elements()} />
							</div>
							<div class="col-span-1">
								<CardCvcElement elements={elements()} />
							</div>
						</div>
						<AddresscElement elements={elements()} />
					</div>
				)}
			</Show>
		</Suspense>
	);
}
