import { createEffect, createMemo, createSignal, Show, useContext } from 'solid-js';
import { ActivityIndicator, Button, Errors, Form, Input, Label, TextField, TextLink } from '@troon/ui';
import { useTrackEvent } from '@troon/analytics';
import { createAsync, useAction, useNavigate, useSearchParams, useSubmission } from '@solidjs/router';
import { IconCheck, IconCircleCheck } from '@troon/icons';
import { twJoin } from '@troon/tailwind-preset/merge';
import { Content } from '../../../components/content';
import { GenericLayout } from '../../../layouts/generic';
import { useUser } from '../../../providers/user';
import { logout } from '../../auth/components/logout-action';
import { AuthFlow, useAuthStore } from '../../../partials/auth/auth';
import { getApiClient, gql, mutationAction, useMutation } from '../../../graphql';
import { Grid, GridThird, GridTwoThirds } from '../../../components/layouts/grid';
import { CardsCtx } from '../../../providers/card';
import { useStripe } from '../../../providers/stripe';
import { Elements } from './_components/elements';
import type { TroonCardSubscriptionIntent } from '../../../graphql';
import type { FlowStep } from '../../../partials/auth/flow';
import type { JSX } from 'solid-js';

export default function CardCheckout() {
	const user = useUser();
	const cards = useContext(CardsCtx);
	const trackEvent = useTrackEvent();
	const handleLogout = logout(trackEvent, '/card/checkout');
	const [params] = useSearchParams<{ id: string }>();
	const { setClientSecret } = useStripe();
	const navigate = useNavigate();

	const [subscription, setSubscription] = createSignal<TroonCardSubscriptionIntent>();
	const applyPromoCode = useMutation(applyPromoCodeAction);
	const promoCodeSubmission = useSubmission(applyPromoCode);

	const confirmSubmissionAction = useMutation(confirmSubscriptionMutation);
	const confirmSubscription = useAction(confirmSubmissionAction);
	// const confirmSubmission = useSubmission(confirmSubmissionAction);

	const { elements, stripe } = useStripe();

	const cardInfo = createMemo(() => {
		return cards()!.find((card) => card.id === params.id)!;
	});

	createEffect(() => {
		if (subscription()?.stripeClientSecret) {
			setClientSecret(subscription()!.stripeClientSecret);
		}
	});

	createEffect(() => {
		if (promoCodeSubmission.result?.data?.cardSubscription) {
			setSubscription(promoCodeSubmission.result!.data!.cardSubscription as unknown as TroonCardSubscriptionIntent);
		}
	});

	createAsync(async () => {
		if (!params.id) {
			navigate('/card/', { replace: true });
			return;
		}
		if (!user()) {
			setSubscription(undefined);
			return;
		}

		if (subscription()) {
			return;
		}

		const data = await getApiClient().mutation(query, { productId: params.id! }, {});
		setSubscription((data.data?.cardSubscription as unknown as TroonCardSubscriptionIntent) ?? undefined);
	});

	return (
		<GenericLayout>
			<Content>
				<Grid>
					<GridTwoThirds class="flex flex-col gap-11">
						<h1 class="sr-only text-4xl font-semibold md:not-sr-only">Complete purchase</h1>

						<section>
							<Show
								when={user()}
								fallback={
									<div class="flex flex-col gap-4">
										<div class="flex flex-row gap-4">
											<div
												role="presentation"
												class="flex size-10 shrink items-center justify-center rounded-full bg-brand-100 text-xl font-semibold text-brand"
											>
												1
											</div>
											<h2 class="text-xl font-semibold leading-10">Log in or sign up</h2>
										</div>
										<AuthFlow inline headers={authHeaders} showSteps={false} />
									</div>
								}
							>
								{(user) => (
									<div class="flex flex-row gap-4">
										<div class="flex size-10 shrink items-center justify-center rounded-full bg-brand-100 text-brand">
											<IconCheck class="size-6" />
										</div>
										<div class="flex grow flex-wrap justify-between">
											<div class="min-w-64 grow">
												<h2 class="text-xl font-semibold leading-10">Account information</h2>
												<ul class="flex flex-col gap-1">
													<li>
														{user().me.firstName} {user().me.lastName}
													</li>
													<li>{user().me.email}</li>
													<li>{user().me.troonRewardsId}</li>
												</ul>
											</div>

											<div>
												<TextLink
													href="/auth/logout"
													onClick={(e) => {
														e.preventDefault();
														handleLogout();
													}}
												>
													Log out
												</TextLink>
											</div>
										</div>
									</div>
								)}
							</Show>
						</section>

						<section class="flex flex-col gap-4">
							<div class="flex flex-row gap-4">
								<div
									role="presentation"
									class={twJoin(
										'flex size-10 shrink items-center justify-center rounded-full text-xl font-semibold ',
										user() ? 'bg-brand-100 text-brand' : 'bg-neutral-300 text-neutral-700',
									)}
								>
									2
								</div>
								<h2 class="text-xl font-semibold leading-10">Payment information</h2>
							</div>
							<Show when={user()}>
								<Elements />
							</Show>
						</section>
					</GridTwoThirds>

					<GridThird>
						<div>
							<h2>{cardInfo().subscriptionName}</h2>
							<p>{cardInfo().amount.displayValue}/year</p>
						</div>

						<Show when={user()}>
							<div>
								<Show when={subscription()} fallback={<ActivityIndicator />}>
									{(subscription) => (
										<>
											<Form action={applyPromoCode} document={applyPromoCodeToTroonCardSubscription}>
												<input type="hidden" name="subscriptionId" value={subscription()?.troonCardSubscriptionId} />
												<TextField name="code">
													<Label suppressRequired>Promo code</Label>
													<Input />
												</TextField>

												<Button type="submit">Submit</Button>
												<Errors />
											</Form>
											<ul>
												<li>Promo code {subscription().discountAmount.displayValue}</li>
												<li>Tax {subscription().taxAmount.displayValue}</li>
												<li>Total {subscription().totalAmount.displayValue}</li>
											</ul>
										</>
									)}
								</Show>
								<Button
									type="button"
									onClick={async () => {
										const billingAddress = await elements()!.getElement('address')?.getValue();

										const res = await stripe()!.confirmCardPayment(subscription()!.stripeClientSecret, {
											payment_method: {
												card: elements()!.getElement('cardNumber')!,
												billing_details: {
													name: billingAddress?.value.name,
													address: billingAddress?.value.address,
												},
											},
										});

										if (!res?.error) {
											const data = new FormData();
											data.set('subscriptionId', subscription()!.troonCardSubscriptionId);
											const result = await confirmSubscription(data);
											if (result.data) {
												navigate('/account');
											}
										}
									}}
								>
									Subscribe & pay
								</Button>
							</div>
						</Show>
					</GridThird>
				</Grid>
			</Content>
		</GenericLayout>
	);
}

const query = gql(`mutation setupTroonCardSubscription($productId: String!) {
  cardSubscription: setupTroonCardSubscription(troonCardSubscriptionProductId: $productId) {
    ...Subscription
  }
}
fragment Subscription on TroonCardSubscriptionIntent {
  stripeClientSecret
  stripeCustomerId
  subscriptionName
  troonCardSubscriptionId
  totalAmount {
    ...Currency
  }
  subtotalAmount {
    ...Currency
  }
  discountAmount {
    ...Currency
  }
  taxAmount {
    ...Currency
  }
}`);

const applyPromoCodeToTroonCardSubscription = gql(`mutation applyPromoCode($subscriptionId: String!, $code: String!) {
  cardSubscription: applyPromoCodeToTroonCardSubscription(troonCardSubscriptionId: $subscriptionId, promoCode: $code) {
    ...Subscription
  }
}`);

const applyPromoCodeAction = mutationAction(applyPromoCodeToTroonCardSubscription);

const confirmSubscription = gql(`mutation confirmTroonCardSubscription($subscriptionId: String!) {
  confirmation: confirmTroonCardSubscriptionPayment(troonCardSubscriptionId: $subscriptionId) {
    id
    status
    subscriptionName
    startDayTime {
      ...DayTime
    }
    endDayTime {
      ...DayTime
    }
    totalAmount {
      ...Currency
    }
    discountAmount {
      ...Currency
    }
    taxAmount {
      ...Currency
    }
    subtotalAmount {
      ...Currency
    }
  }
}`);

const confirmSubscriptionMutation = mutationAction(confirmSubscription);

const authHeaders: Record<FlowStep, () => JSX.Element> = {
	'/auth/': () => (
		<p>
			Enter the email associated with your Troon Card/Troon Rewards Account to log in. If you don’t have one, you can
			create a new account for free.
		</p>
	),
	'/auth/login/': () => {
		const [store] = useAuthStore();
		return (
			<>
				<FoundUser />
				<p>
					We found an existing Troon Rewards account for <b>{store.data.email}</b>. Enter your password to login and
					continue.
				</p>
			</>
		);
	},
	'/auth/join/': () => {
		return (
			<>
				<FoundUser />
				<p>Complete the following account information to set up your Troon Account.</p>
			</>
		);
	},
	'/auth/reset/': () => (
		<>
			<FoundUser />
			<p>Check your email for a confirmation code.</p>
		</>
	),
	'/auth/reset/password/': () => (
		<>
			<FoundUser />
			<p>Create a password with at least 8 letters and numbers.</p>
		</>
	),
	'/auth/magic-link/': () => {
		const [store] = useAuthStore();
		return (
			<>
				<FoundUser />
				<p>
					We sent a login code to <b>{store.data.email}</b>. Copy the code and enter it below to continue logging in.
				</p>
			</>
		);
	},
};

function FoundUser() {
	const [store] = useAuthStore();
	return (
		<p class="flex flex-row items-center gap-2 rounded-lg border border-neutral-300 bg-neutral-100 px-4 py-3 text-neutral-800">
			<IconCircleCheck class="size-6 text-green-500" />
			{store.data.email}
		</p>
	);
}
