import { colors } from '@troon/tailwind-preset/colors';
import { createSignal, onMount } from 'solid-js';
import type { StripeElements } from '@stripe/stripe-js';

type Props = {
	elements: StripeElements;
};

export function CardNumberElement(props: Props) {
	const [ref, setRef] = createSignal<HTMLDivElement>();

	onMount(() => {
		const el = props.elements.create('cardNumber', {
			showIcon: true,
			classes: {
				base: 'relative size-full min-w-min rounded-t-xl border border-b-0 border-solid border-neutral bg-white px-4 py-3 outline-none',
				focus: 'z-10 ring-1 ring-brand-700',
				invalid: 'border-red-600',
			},
			style: {
				base: {
					fontFamily:
						'Poppins, ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
					fontSize: '16px',
				},
				invalid: { color: colors.red['500'] },
			},
		});

		el.mount(ref()!);
	});

	return <div ref={setRef} />;
}
